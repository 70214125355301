.slide-in-right {
  animation: slide-in-right 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-top {
  animation: slide-in-top 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.pulse {
  animation: pulse 3s ease-in-out both;
}

.fade-in {
  animation: fade-in 3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s both;
}

@keyframes slide-in-right {
  0% {
    transform: translateZ(-140px) translateX(100px);
    opacity: 0;
  }

  100% {
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    transform: translateZ(-140px) translateY(100px);
    opacity: 0;
  }

  100% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

@keyframes pulse {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }

  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }

  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }

  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }

  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
